@import "../../../src/breakpoints.scss";

.about-main {
	display: flex;
	flex-direction: row;
	background-image: url("../../images/wood-bg.webp");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	min-height: 100vh;
	height: fit-content;
	position: relative;
	justify-content: space-around;

	.about-text {
		display: flex;
	  flex-direction: column;
		padding: 6em 2em 8em 2em;
		color: var(--color-white);
		font-family: var(--font-rokkitt);
		max-width: 1531px;
		gap: 2em;

		h1 {
			display: flex;
			justify-content: center;
			font-size: 46px;

			@include xs {
				font-size: 24px;
			}

			@include sm {
				font-size: 36px;
			}

			@include md {
				font-size: 46px;
			}

			.return-home {
				padding-left: 40px;

				:hover {
					color: #000;
				}

				@media (max-width: 1200px) {
					display: none;

				}
			}
		}

		p {
			font-size: 30px;

			@include xs {
				font-size: 18px;
			}

			@include sm {
				font-size: 24px;
			}

			@include md {
				font-size: 30px;
			}
		}

		.julie-text {
			display: flex;
			flex-direction: row;
			gap: 2em;

			@include xs {
				flex-direction: column;
			}

			@include lg {
				flex-direction: row;
			}
		}

		.text-right {
			display: flex;
			flex-direction: column;
			max-width: 937px;
		}

		.text-left {
			display: flex;
			flex-direction: column;
			max-width: 937px;
		}

		.lewis-text {
			display: flex;
			flex-direction: row;
			gap: 2em;

			@include xs {
				flex-direction: column-reverse;
			}

			@include lg {
				flex-direction: row;
			}
		}

		.julie {
			max-width: 100%;
			min-width: 250px;
			object-fit: cover;
			object-position: center;
			border-radius: 20px;

			@include xs {
				width: 100%;
				min-width: auto;
				max-width: 100%;
			}

			@include lg {
				max-width: 535px;
				min-width: 500px;
			}

			@include xl {
				max-width: 535px;
				min-width: 400px;
			}
		}

		.lewis {
			max-width: 535px;
			min-width: 250px;
			object-fit: cover;
			object-position: center;
			border-radius: 20px;

			@include xs {
				width: 100%;
				min-width: auto;
				max-width: 100%;
			}

			@include lg {
				max-width: 535px;
				min-width: 500px;
			}

			@include xl {
				max-width: 535px;
				min-width: 400px;
			}
		}

		.about-contact-button:hover {
			cursor: pointer;
			color: var(--color-black);
		}
	}
}


