.services-section {
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.158), rgba(0, 0, 0, 0.925)), url("../../images/wood-bg.webp");
}

.services-main {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: fit-content;
	min-height: 100vh;
	max-width: 1454px;
	width: 100%;
	margin: auto;

	@media only screen and (max-width: 800px) {
		background-position: center;
		background: url("../../images/jlp-oven-1@2x.webp") 0%;
		background-size: 1000px;
		background-repeat: no-repeat;
	}

	.services-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.services-info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 4rem;
		color: #E06F27;

		@media only screen and (max-width: 600px) {
			margin-top: 9rem;
		}

		.we-do {
			font-size: var(--font-size-3xl);
			font-family: var(--font-plus-jakarta-sans);

			@media only screen and (max-width: 800px) {
				font-size: var(--font-size-xl);
			}

			@media only screen and (max-width: 600px) {
				font-size: var(--font-size-lg);
			}
		}

		.we-do-items {
			font-size: var(--font-size-lg);
			font-family: var(--font-ruda);
			color: var(--color-white);

			@media only screen and (max-width: 800px) {
				font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 600px) {
				font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 450px) {
				font-size: var(--font-size-xs);
			}
		}

		:hover {
			color: #E06F27;
		}
	}

	.return-home {
		margin-left: 4rem;
		margin-top: 2rem;

		:hover {
			color: #000;
		}
	}

	.contact-image {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (max-width: 800px) {
			display: none;
		}

		:hover {
			animation: myAnim 2s ease 0s normal forwards;
		}
	}

	@keyframes myAnim {
		0%,
		100% {
			transform: translateX(0%);
			transform-origin: 50% 50%;
		}

		15% {
			transform: translateX(-30px) rotate(-6deg);
		}

		30% {
			transform: translateX(15px) rotate(6deg);
		}

		45% {
			transform: translateX(-15px) rotate(-3.6deg);
		}

		60% {
			transform: translateX(9px) rotate(2.4deg);
		}

		75% {
			transform: translateX(-6px) rotate(-1.2deg);
		}
	}
}
