.banner-body {
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: center;
	top: 0;
	height: 60px;
	width: 100%;
	background-color: var(--color-black);
	z-index: 999;

	@media only screen and (max-width: 1200px) {
			height: 100px;
		}

	.banner-content {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.banner-body-text {
		display: flex;
		flex-direction: row;
		align-self: center;
		justify-self: center;
		text-align: center;
		padding: 15px;
		padding-bottom: 20px;
		font-family: var(--font-montserrat);
		font-size: var(--font-size-xs);
		font-weight: 200;
		color: var(--color-white);
	}

	.pizza-icon {
		padding-top: 13px;
		height: 32px;
	}

	.close {
		position: absolute;
		right: 0%;
		display: flex;
		flex-direction: row;
		align-self: center;
		justify-self: flex-end;
		padding: 15px;
		padding-bottom: 20px;

		button {
			background-color: transparent;
			border: none;
			cursor: pointer;
		}

		.close-button {
			align-self: center;
			justify-self: center;
			text-align: center;
			height: 20px;
		}
	}
}
