.footer {
	display: flex;
	flex-direction: column;
	min-height: 420px;
	height: auto;
	background-color: var(--color-black);

	.footer-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 45px 50px;

		@media (max-width: 960px) and (min-width: 0px) {
			align-self: center;
			justify-self: center;
			flex-direction: column;
		}

		.footer-logo {
			width: 400px;
			justify-self: flex-start;

			@media (max-width: 960px) and (min-width: 0px) {
				width: 200px;
				align-self: center;
			}

			.footer-logo-img {
				width: 230px;
				cursor: pointer;

				@media (max-width: 960px) and (min-width: 0px) {
					width: 100%;
				}
			}

		}

		.footer-nav {
			display: flex;
			flex-direction: row;
			gap: 60px;
			font-size: var(--font-size-sm);
			font-family: var(--font-ruda);
			color: var(--color-white);

			.footer-nav-item:hover {
				cursor: pointer;
				text-decoration: underline;
			}

			@media (max-width: 1200px) and (min-width: 0px) {
				display: none;
			}
		}

		.footer-contact {
			display: flex;
			justify-content: flex-end;
			width: 400px;
			flex-direction: column;
			align-items: flex-end;

			.footer-contact-button:hover {
				color: var(--color-black);
			}

			.footer-contact-food-rating {
				border-radius: .5em;
			}

			@media (max-width: 960px) and (min-width: 0px) {
				justify-content: center;
				align-items: center;

				button {
					display: none;
				}
			}
		}
	}

	.copyright {
		display: flex;
		flex-direction: column;
		align-self: center;
		justify-self: center;
		text-align: center;
		padding-top: 50px;
		padding-bottom: 50px;
		font-family: var(--font-montserrat);
		font-size: var(--font-size-2xs);
		color: var(--color-white);

		.author-link, .author-link:visited {
			color: var(--color-white);

			&:hover, &:active {
				color: var(--color-primary);
			}
		}

		.author-img {
			width: 28px;
			vertical-align: middle;
		}
	}


	.mobile {
		display: flex;
		justify-content: center;
		position: fixed;
		bottom: 0;
		width: 100%;
		background-color: #000;

		@media (min-width: 1200px) {
			display: none;
		}
	}

	.mobile-nav {
		display: flex;
		width: 100%;
		flex-direction: row;
		justify-content: space-evenly;
		gap: 1rem;
		padding-top: 1.5em;
		padding-bottom: 1.5em;
		flex-wrap: wrap;
		font-family: var(--font-montserrat);

		.mobile-nav-item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			gap: .25em;
			cursor: pointer;
		}

		.mobile-nav-item,
		.mobile-nav-item:visited {
			color: var(--color-white);
		}

		.mobile-nav-item:hover,
		.mobile-nav-item:active {
			color: var(--color-primary);
		}

		span img,
		span:visited img {
			filter: invert(0%) sepia(5%) saturate(7482%) hue-rotate(273deg) brightness(98%) contrast(105%);
		}

		span:hover img,
		span:active img {
			filter: invert(49%) sepia(67%) saturate(4984%) hue-rotate(349deg) brightness(96%) contrast(99%);
		}
	}

		@media (max-width: 1200px) and (min-width: 0px) {
			padding-bottom: 100px;
		}
}
