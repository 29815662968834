@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ruda:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rosario:wght@700&display=swap");

html {
	background-color: #000;
}

body {
	margin: 0;
	line-height: normal;
}

:root {
	/* fonts */
	--font-ruda: Ruda;
	--font-rosario: Rosario;
	--font-rokkitt: Rokkitt;
	--font-plus-jakarta-sans: "Plus Jakarta Sans";
	--font-montserrat: Montserrat;

	/* font sizes */
	--font-size-2xs: 13px;
	--font-size-xs: 17px;
	--font-size-sm: 24px;
	--font-size-base: 32px;
	--font-size-lg: 36px;
	--font-size-xl: 40px;
	--font-size-2xl: 48px;
	--font-size-3xl: 55px;

	/* Colors */
	--color-white: #fff;
	--color-primary: #f4501d;
	--color-gray: rgba(0, 0, 0, 0.5);
	--color-black: #000;

	/* border radiuses */
	--br-md: 20px;
}
