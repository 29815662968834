@import "../../../src/breakpoints.scss";

.landing {
	display: flex;
	flex-direction: column;
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.158), rgba(0, 0, 0, 0.7)),url("https://i.imgur.com/G7kMRwB.jpg");
	background-color: #000;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: fit-content;
	min-height: 100vh;
	padding-bottom: 10em;

	.landing-body {
		display: flex;
		flex-direction: column;
		align-self: center;
		justify-self: center;
		text-align: center;
		max-width: 1300px;
		color: var(--color-white);
		animation: landing 1s ease 0s 1 normal forwards;

		@keyframes landing {
			0% {
				opacity: 0;
				transform: scale(0.6);
			}

			100% {
				opacity: 1;
				transform: scale(1);
			}
		}

		.landing-body-title {
			font-family: var(--font-rosario);
			font-size: 48px;

			@media (max-width: 960px) {
				display: flex;
				flex-direction: column;
				align-self: center;
				justify-self: center;
				text-align: center;
				inline-size: 250px;
				line-height: 50px;
				overflow-wrap: break-word;
				font-size: var(--font-size-xl);
			}
		}

		.landing-body-content {
			font-family: var(--font-rokkitt);
			font-size: 40px;
			font-weight: 300;

			@media (max-width: 1100px) {
				display: flex;
				flex-direction: column;
				align-self: center;
				justify-self: center;
				text-align: center;
				inline-size: 400px;
				overflow-wrap: break-word;
				line-height: 35px;
				font-size: var(--font-size-sm);
			}

			@media (max-width: 500px) {
				font-size: var(--font-size-sm);
				inline-size: 300px;
			}
		}

		.we-are-located {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			.our-location {
				padding-left: 10px;
				font-size: var(--font-size-sm);
				font-family: var(--font-ruda);

				@media only screen and (max-width: 800px) {
					font-size: var(--font-size-sm);
				}

				@media only screen and (max-width: 600px) {
					font-size: var(--font-size-sm);
				}

				@media only screen and (max-width: 450px) {
					font-size: var(--font-size-xs);
				}
			}

			.locate {
				height: 30px;
				color: var(--color-white);

				@media only screen and (max-width: 1200px) {
					gap: 30px;
					height: 30px;
				}

			}

			@media only screen and (max-width: 800px) {
				font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 600px) {
				font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 450px) {
				font-size: var(--font-size-xs);
			}
		}

	}

	.return-home {
		display: flex;
		justify-content: center;
		padding-top: 2rem;

		:hover {
			color: #000;
		}

		@media (min-width: 960px) {
			display: none;
		}
	}
}


