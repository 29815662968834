.landing-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 45px 50px;

	.landing-logo {
		justify-self: flex-start;
		width: 400px;
		cursor: pointer;

		.landing-logo-img {
			width: 100%;

			@media (max-width: 960px) {
				display: flex;
				flex-direction: column;
				align-self: center;
				justify-self: center;
				width: 100%;
			}
		}
	}

	.landing-nav {
		display: flex;
		flex-direction: row;
		gap: 60px;
		font-size: var(--font-size-sm);
		font-family: var(--font-ruda);
		color: var(--color-white);

		.landing-nav-item:hover {
			cursor: pointer;
			text-decoration: underline;
		}

		@media (max-width: 1200px) and (min-width: 0px) {
			display: none;
		}
	}

	.landing-contact {
		display: flex;
		justify-content: flex-end;
		width: 400px;


		.landing-contact-button:hover {
			color: var(--color-black);
		}

		@media (max-width: 960px) {
			display: none;
		}
	}
}
