.contact-section {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.158), rgba(0, 0, 0, 0.8)), url("../../images/wood-bg.webp");
	color: var(--color-white);
	padding-bottom: 10em;
}

.contact-main {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: fit-content;
	min-height: 100vh;
	max-width: 1500px;
	width: 100%;
	margin: auto;

	@media only screen and (max-width: 800px) {
		background-position: center;
		background: url("../../images/jlp-oven-1@2x.webp") 0%;
		background-size: 1000px;
		background-repeat: no-repeat;
	}

	.contact-body, .contact-image {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.contact-info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 4rem;
		padding-top: 100px;

		@media only screen and (max-width: 600px) {
			margin-top: 4rem;
			margin-left: 2rem;
		}

		.get-in-touch {
			font-size: var(--font-size-3xl);
			font-family: var(--font-plus-jakarta-sans);

			@media only screen and (max-width: 800px) {
				font-size: var(--font-size-xl);
			}

			@media only screen and (max-width: 600px) {
				font-size: var(--font-size-lg);
			}
		}


		.mobile {
			font-size: var(--font-size-lg);
			font-family: var(--font-ruda);

			@media only screen and (max-width: 800px) {
				font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 600px) {
				font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 450px) {
				font-size: var(--font-size-xs);
			}
		}

		.email {
			font-size: var(--font-size-lg);
			font-family: var(--font-ruda);

			@media only screen and (max-width: 800px) {
				font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 600px) {
				font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 450px) {
				font-size: var(--font-size-xs);
			}
		}

		.we-are-located {
			display: flex;
			flex-direction: row;
			font-size: var(--font-size-lg);
			font-family: var(--font-ruda);
			align-items: baseline;

			.locate {
				height: 39px;
				color: var(--color-white);

				.locate :hover {
					color: var(--color-primary);
				}

				@media only screen and (max-width: 1200px) {
					gap: 30px;
					height: 30px;
				}

			}

			@media only screen and (max-width: 800px) {
						font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 600px) {
						font-size: var(--font-size-sm);
			}

			@media only screen and (max-width: 450px) {
				font-size: var(--font-size-xs);
			}

				.our-location {
					padding-left: 20px;
					font-size: var(--font-size-lg);
					font-family: var(--font-ruda);

					@media only screen and (max-width: 800px) {
						font-size: var(--font-size-sm);
					}

					@media only screen and (max-width: 600px) {
						font-size: var(--font-size-sm);
					}

					@media only screen and (max-width: 450px) {
						font-size: var(--font-size-xs);
					}
				}

		}

	}


	.website {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 4rem;
		padding-top: 1rem;
		font-size: var(--font-size-lg);
		font-family: var(--font-ruda);

		@media only screen and (max-width: 800px) {
			font-size: var(--font-size-sm);
		}

		@media only screen and (max-width: 600px) {
			font-size: var(--font-size-sm);
			margin-top: 4rem;
			margin-left: 2rem;
		}

		@media only screen and (max-width: 450px) {
			font-size: var(--font-size-xs);
		}
	}

	.social-media-icons {
		display: flex;
		flex-direction: row;
		gap: 50px;
		margin-left: 4rem;
		font-size: var(--font-size-lg);
		font-family: var(--font-ruda);

		@media only screen and (max-width: 600px) {
			margin-left: 2rem;
		}

		@media only screen and (max-width: 1200px) {
			gap: 30px;
		}

		.facebook-icon {
			@media only screen and (max-width: 1200px) {
				width: 30px;
			}
		}

		.twitter-icon {
			@media only screen and (max-width: 1200px) {
				width: 30px;
			}
		}

		.instagram-icon {
			@media only screen and (max-width: 1200px) {
				width: 30px;
			}
		}
	}

	.return-home {
		margin-left: 4rem;
		margin-top: 5rem;

		:hover {
			color: #000;
		}

		@media only screen and (max-width: 600px) {
			margin-left: 2rem;
		}
	}

	.jlp-oven-1 {
		width: 721px;
		padding-top: 150px;

		@media (max-width: 800px) {
			display: none;
		}
	}

	a, a:visited {
		color: var(--color-white);
	}

	a:hover, a:active {
		color: var(--color-primary);
	}

	a img, a:visited img {
		filter: invert(0%) sepia(5%) saturate(7482%) hue-rotate(273deg) brightness(98%) contrast(105%);
	}

	a:hover img, a:active img {
		filter: invert(49%) sepia(67%) saturate(4984%) hue-rotate(349deg) brightness(96%) contrast(99%);
	}
}
